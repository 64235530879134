@import "~antd/dist/antd.dark.less";
.ant-layout {
	background: @background-color;

	@media only screen and (max-width: 768px) {
		width: unset !important;
	}
}

.anonymous-container,
.maintenance-container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.anonymous-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	@media only screen and (max-width: 576px) {
		padding-top: 0;

	}

}

.logo-container {
	margin-top: 3vh;
	margin-bottom: 3vh;
	text-align: center;

	img {
		max-width: 300px;
	}
}

.footer-container {
	margin: 3vh auto;
	text-align: center;
}

.app-container {
	height: 100vh;

	@media only screen and (max-width: 576px) {
		height: 100%;
		overflow-x: hidden;
	}
}

.login-container {
	min-height: 100vh;

	@media only screen and (max-width: 576px) {
		height: 100%;
	}
}

.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

.site-layout-background {
	overflow-y: auto;
	min-height: 280px;
	@media only screen and (max-width: 576px) {
		overflow: hidden;
		position: relative;
	}
}

.page-container {
	background: @page-container-bg;
	max-width: 1280px;
	margin: auto;
	padding: 20px;
}

.filter-panel {
	margin-bottom: 8px;

	>div {
		margin: 8px !important;
	}

	.ant-form-item>.ant-form-item-label {
		display: flex;
		align-items: center;
	}

	.filter-button-group {
		width: 100%;

		Button {
			width: 100px !important;
			margin-right: 8px !important;

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}
}

.ant-row-style {
	cursor: pointer;
}

.statistic-container {
	background-color: @card-warm-color; //rgb(240, 240, 240);
	padding: 4px 8px;
	margin-top: 8px;
	color: black;
	display: flex;

	div {
		padding: 0 16px 0 4px;
	}

	span {
		margin-left: 4px;
		font-weight: bold;
	}
}

.table-container {
	overflow-x: scroll;
	background: #141414;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	background-color: @table-header-color;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
	color: @table-header-color;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
	color: @side-nav-customer-service-text;
}

.ant-table-thead th.ant-table-column-sort {
	color: @side-nav-customer-service-text;
}

.maintenance-container {
	padding-top: 0;
	border-radius: 12px 0 0 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px;

	@media only screen and (max-width: 576px) {
		.ant-result {
			padding: 0px;
		}

		padding: 8px;
	}

	.maintenance-text {
		margin-top: 16px;

		@media only screen and (max-width: 576px) {
			font-size: 16px;
		}

		span {
			display: block;
		}
	}

	.ant-result-title {
		color: white !important;
	}
}

.user-guide {
	//display: none;
	color: #e8be71;
	border-color: #e8be71;
}

.cover-container-phone img {
	max-height: 150px;
	max-width: 280px;
	margin-top: -80px;
}

.auth-container .cover-container img {
	max-height: 220px;
	max-width: 280px;
	margin-top: -80px;
}

.ant-btn-primary {
	color: @button-text;
}

.tnc-frame{
	max-height: 300px;
	border: 1px solid @primary-color;
	padding: 10px 20px 10px 20px;
	margin-bottom: 10px;
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: #ffffff;
	color: #000000
}


::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
	background: @primary-color;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #cccccc;
}

.tnc-checkbox{
	margin-bottom: 0px !important;
}

.video-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;