@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-1 {
  font-size: 24px;
  font-weight: 600;
}

.header-2 {
  font-size: 20px;
  font-weight: 600;
}

.font-subtitle1 {
  font-size: 16px;
  font-weight: 600;
}

.font-subtitle2 {
  font-size: 12px;
  font-weight: 600;
}

.font-body {
  font-size: 16px;
}

.font-caption {
  font-size: 12px;
}

html,
body,
#root,
.App {
  height: 100%;
}