.side-nav-logo {
	display: flex;
	align-items: center;
	justify-content: center;

	.logo-default {
		max-height: 130px;
		max-width: 280px;
		margin-top: 10px;
		margin-right: 15px;
		margin-bottom: -10px;
	}

	.logo-collapse {
		height: 40px;
		//width: 300%;
		object-fit: cover;
		object-position:left;
		margin-top: 20px;
	}

	span {
		padding-left: 8px;
		display: inline-block;
		color: white;
	}
}

.ant-layout-sider {
	background: @background-color;
}

.ant-divider-horizontal {
	margin: 16px 0;
	background-color: @primary-1;
	opacity: 0.4;
}

.profile {
	display: flex;
	flex-direction: column;
	margin: 0 28px 8px 28px;

	>div {
		margin: 8px 0;
		display: flex;
		flex-direction: row;

		>img {
			margin-right: 20px;
			width: 20px;
		}

		>div:last-child {
			margin-left: 20px;
		}
	}
}

.shotcut-icon,
.shotcut-icon-collapse {
	display: flex;
	height: auto;

	Button {
		height: auto;
	}

	.link-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			margin-bottom: 8px;
			width: 30px;
		}
	}
}

.shotcut-icon {
	flex-direction: row;
	justify-content: space-evenly;
}

.shotcut-icon-collapse {
	flex-direction: column;

	Button {
		margin: 8px;
	}
}

.customer-service-container-collapsed,
.customer-service-container {
	background-color: @side-nav-customer-service-bg;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	.anticon {
		font-size: 16px;
	}

	>.ant-divider-horizontal {
		margin-top: 0;
	}

	.customer-service-title {
		padding: 0px 20px 4px 20px;
		display: flex;
		justify-content: flex-start;
		font-weight: bold;
		font-size: 14px;
		color: @side-nav-customer-service-text;
	}

	.customer-service-subcontainer {
		display: flex;
		flex-direction: column;
		padding: 4px 20px;

		>div {
			display: flex;
			align-items: center;
			padding-bottom: 6px;
			font-size: 12px;
		}

		.customer-service-icon {
			color: @side-nav-customer-service-text;
		}

		.line-icon {
			width: 22px;
			margin-left: -3px;
		}

		.customer-service-details {
			padding-left: 8px;
			color: @side-nav-customer-service-text;

			a {
				color: @side-nav-customer-service-text;
			}

			button{
				font-size: 12px;
			}
		}

		.version {
			color: @side-nav-customer-service-text;
			font-size: 8px;
			font-weight: normal;
		}
	}
}

.customer-service-container-collapsed {
	flex-direction: column;
	padding: 4px;
	align-items: center;
}

.slider-container {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;

	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.ant-menu {
	background: @background-color;
}

.side-menu-theme {
	.ant-menu-item a {
		color: @side-nav-text-color;
	}

	.ant-menu-title-content {
		color: @side-nav-text-color;
	}

	.ant-menu-item-icon {
		color: @side-nav-icon-color;
	}

	.ant-menu-item-selected {
		background-color: @primary-1;
		color: @button-text;
	}

	.ant-menu-item-selected a,
	.ant-menu-item-selected a:hover,
	.ant-menu-item-selected:hover {
		color: @button-text;
	}
}

.side-menu-divider {
	// background-color: @primary-1;
	background: linear-gradient(90deg, @background-color, @primary-color, @background-color);
	height: 1px;
	border-top: none;
}

.popover-text {
	color: @button-text;
}

.side-nav-background {
	background-color: @background-color;
}

.ant-image-mask {
	display: none;
}

.ant-menu-submenu-popup {
	display: none;
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;