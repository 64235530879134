.auth-container {
    width: 850px;
    margin: auto;
    box-shadow: 2px 2px 5px @box-shadow-color;
    border-radius: 12px;
    background-color: @background-color;
    display: flex;

    .cover-container {
        border-radius: 12px 0 0 12px;

        @media only screen and (max-width: 576px) {
            display: none;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ant-typography {
            margin-top: 30px; //remove after this
            //color: @auth-text-color !important;
        }
    }

    .login-welcome-title {
        margin-top: 30px !important;
    }

    .default-logo {
        margin-top: -20px !important;

        &:not([src]) {
            opacity: 0;
        }
        //padding: 24px 12px;
        // image {
        //     height: 150px;
        //     width: 280px;
        //     margin-top: -40px;
        // }

        @media only screen and (max-width: 576px) {
            padding: 20px 12px 4px;
        }

    }

    .welcome-text {
        margin-bottom: 0px !important;
    }

    >div {
        flex: 1;

    }

    @media only screen and (max-width: 576px) {
        width: unset;
        // max-width: 480px;
        border-radius: 0px;
    }

}

.auth-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 12px 12px 0 0;

    @media only screen and (max-width: 576px) {
        padding: 12px 24px;
        border-radius: 0px;
    }

    text-align: center;

    >h4 {
        margin: 0;
        color: @primary-1;
    }
}

.auth-title-long {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 12px 12px 0 0;

    @media only screen and (max-width: 576px) {
        padding: 12px;
        border-radius: 0px;
    }

    text-align: center;

    >h4 {
        font-size: 18px !important;
        margin: 0;
        color: @primary-1;
    }
}


.auth-content {
    padding: 0px 24px 0;
}

.forgot-password-container {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 12px;
    text-align: right;

    @media only screen and (max-width: 576px) {
        text-align: left;
        padding: 0 24px 8px 24px;
    }

    button {
        padding: 0 4px;
        color: @primary-1;
    }
}

.sms-same-row-container {
    display: flex;

    >div {
        flex: 1;
    }
}

.auth-steps-container {
    padding: 24px 24px 8px;

    @media only screen and (max-width: 576px) {
        padding: 12px 12px 8px;
    }
}

.ant-steps-small .ant-steps-item-title {
    padding-right: 12px;
    font-size: 12.5px !important;
    line-height: 24px;

    @media only screen and (max-width: 576px) {
        font-size: 10.5px !important;
    }
}

.auth-success-message {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 16px;
}

.auth-button {
    padding-top: 12px;

    Button {
        background-color: @primary-1;
    }

    @media only screen and (max-width: 576px) {
        padding-top: 8px;
    }
}

.auth-error {
    margin-bottom: 24px;
}

.gif-logo {
    margin-top: -30px;

    @media only screen and (max-width: 576px) {
        padding: 20px 12px 4px;
    }

}

.footer-container {
    margin: 3vh auto;
    text-align: center;
    color: white;

    @media only screen and (max-width: 576px) {
        color: #000000;
    }
}

.ant-alert-info {
    background-color: @side-nav-customer-service-bg;

    .ant-alert-content {
        >div {
            color: @side-nav-customer-service-text;
        }
    }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px @background-color inset !important;
    border-radius: 0px !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: @button-text  !important;
}

.cover-container-phone {
    display: none;

    @media only screen and (max-width: 576px) {
        display: block;

        .default-logo {
            width: 100%
        }

        >img {
            margin: 20px auto;
            display: block;
        }
    }
}

.ant-picker{
    width: 100%;
}

.redirect-container {
    margin-bottom: 24px;
    padding: 5px 15px;
    border: 1px solid @primary-1;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    color: @primary-1; 
}

.captcha-panel {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    .captcha-input{
        margin-bottom: 0;
        width: 100%;
        .ant-input {
            height: 35px;
        }
    }
    .captcha-refresh-btn {
        padding: 4px;
        height: 35px;
    }
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;