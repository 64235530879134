.profile-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 12px;

	>div {
		background: @card-warm-color;
		width: 280px;
		margin: 4px 0;
		// border: 1px solid @border-color;
		border-radius: 8px;

		>div {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 24px;

			&:first-child {
				padding-top: 20px;

				>div {
					color: @grey-color;
				}
			}

			&:nth-last-child(2) {
				padding-bottom: 12px;
			}
		}

		.profile-details {
			font-size: 30px;
			font-weight: 400;
			display: flex;
			align-items: center;
			color: @profile-details-money-font;

			.icon {
				font-size: 20px;
				color: @grey-color;
				cursor: pointer;
			}
		}
		
		.profile-details-latest-action{
			color: @side-nav-customer-service-text;
		}

		.profile-buttons {
			&:extend(.footer-link-buttons all);
			display: flex;
			justify-content: center;
			padding: 8px 0;
			background-color: @primary-1;
			border-top: 1px solid @primary-1;
			border-radius: 0 0 8px 8px;

			Button {
				color: @button-text;
			}

			.center-divider {
				height: auto;
				background-color: @button-text;
			}
		}
	}
}

@media only screen and (max-width: 1280px) {
	.profile-container {
		>div {
			width: 400px;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.profile-container {
		>div {
			width: 320px;
		}
	}
}

@media only screen and (max-width: 940px) {
	.profile-container {
		>div {
			width: 250px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.profile-container {
		justify-content: center;

		>div {
			width: 250px;
			margin: 4px;
		}
	}
}

@media only screen and (max-width: 576px) {
	.profile-container {
		justify-content: center;
		flex-direction: column;

		>div {
			width: calc(100vw - 24px);
			margin: 4px auto;
		}
	}
}

.disabled-buttons {
	color: @disabled-buttons-color !important;
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;