.home-container {
	height: 100vh;
	max-width: 1280px;
	margin: auto;
	@media only screen and (max-width: 576px) {
		overflow-y: scroll;
		position: absolute;
	}
}

.combine-panel {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;

	@media only screen and (max-width: 576px) {
		flex-direction: column;
	}
}

.lite-competition-container {
	margin-right: 24px;
	max-height: 540px;

	@media only screen and (max-width: 576px) {
		margin-right: 0;
		margin-bottom: 24px;
	}
}

.footer-link-buttons {
	display: flex;
	background: #f7f9fa;
	align-items: center;
	justify-content: space-evenly;
	padding: 8px 0;

	Button {
		color: @link-color;
	}

	.ant-divider-vertical {
		height: 20px;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.marquee-container {
	margin: 8px 12px 0;
	width: auto !important;

	>div {
		padding: 8px 0;
		background-color: @side-nav-customer-service-bg;
		color: @side-nav-customer-service-text;
	}

	@media only screen and (max-width: 576px) {
		margin: 0;
	}
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;