.trading-consultant-container {
	margin: 12px;
	background: @header-profile-bg;
	padding: 20px 24px;

	@media only screen and (max-width: 768px) {
		padding: 16px;
	}

	@media only screen and (max-width: 576px) {
		margin: 12px auto;
		width: 100vw;
		padding: 12px;
	}

	.trading-consultant-header {
		.sub-title {
			color: @grey-color;
		}

		span {
			display: block;
			margin-bottom: 1.5em;
		}
		
		>h4{
			color: @primary-color;
			margin-bottom: 0px;
		}
	}

	.trading-consultant-items {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: flex-start;
		gap: 12px;

		@media only screen and (max-width: 576px) {
			justify-content: center;
			flex-direction: column;
			margin-bottom: 88px;
		}

		>div {
			.advisor-name-container {
				display: flex;
				align-items: center;
				color: @profile-details-money-font;

				.advisor-name {
					font-size: 16px;
					font-weight: bold;
				}

				a {
					display: flex;
					align-items: center;
				}

				.icon {
					font-size: 18px;
					line-height: 24px;
				}
			}

			.advisor-avatar {
				// background-color: @advisor-avatar-bg;

				@media only screen and (max-width: 1280px) {
					margin-right: 4px;
				}
			}

			.advisor-details {
				div {
					flex: 1;
				}
				.advisor-master-field{
					padding: 0 0 8px 0;
					color: @side-nav-customer-service-text;
				}

				.advisor-master-field-long{
					padding: 0 0 8px 0;
					color: @side-nav-customer-service-text;
					font-size: 12px;
				}

				.advisor-performance-month {
					font-size: 16px;
					font-weight: bold;
					padding-bottom: 8px;
					color: @side-nav-customer-service-text;
				}

				.advisor-performance-month-long {
					font-size: 12px;
					font-weight: bold;
					padding-bottom: 8px;
					color: @side-nav-customer-service-text;
				}
			}

			// border: 1px solid @border-color;
			border-radius: 8px;
			min-width: 375px;
			max-width: 600px;
			background: @card-warm-color;

			@media only screen and (max-width: 576px) {
				width: 100%;
				min-width: 0px;
			}
			
			>div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 24px;

				&:first-child {
					padding: 12px;
				}
			}

			.ant-divider-horizontal {
				margin: 0;
			}
		}

		.advisor-detail-container-long{
			white-space: nowrap;
			padding-left: 15px;

			@media only screen and (max-width: 576px) {
				white-space: normal;
			}
			.advisor-roi-percent-long{
				font-size: 12px;
			}

			.advisor-total-lot-long{
				padding-left: 15px;
				padding-right: 20px;
				font-size: 12px;
			}
		}

		.trading-details {
			display: flex;
			justify-content: space-between;
			padding-bottom: 16px;

			>div {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 100%;

				span {
					display: block;

					&:first-child {
						color: @grey-color;
					}

					&:last-child {
						color: black;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
		}

		.trading-buttons {
			display: flex;
			background-color: @primary-1;
			align-items: center;
			justify-content: space-evenly;
			padding: 8px 0;
			border-radius: 0 0 8px 8px;

			.ant-divider-vertical {
				height: 20px;
			}

			Button {
				color: @button-text;
			}

			.followed-button {
				color: @grey-color;
			}

			.button-divider {
				background-color: @button-text;
			}
		}

		.divider-color {
			background-color: @primary-color;
		}
	}
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;