.site-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: @primary-color;
    z-index: 1;
    min-width: 300px;

    >div {
        display: block;
        // margin-right: 16px;
    }

    .header-actions {
        display: flex;
        align-items: center;

        .profile-menu-background{
            padding-right: 16px;
            background-color: @header-profile-bg;
        }

        .profile-menu {
            float: left;
            padding: 0 6px;
            background-color: @header-profile-bg;

            .profile-menu-title {
                padding: 0 0 0 3px;
            }

        }

        .notification-panel {
            margin: 0px 12px;

            .notification {
                color: @white-bg;
                font-size: 24px;
            }
        }

        .avatar {
            line-height: 32px !important;
            margin: 0 8px;
        }
    }
}

.ant-menu-item-selected span,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: @button-text;
}

.inner-menu-profile{
    >li{
        padding: 6px 12px;
        min-width: 150px;
    }
}


@media only screen and (max-width: 600px) {
    .site-layout-header {

        >div .profile-menu .profile-menu-title {
            display: none;
            
        }
    }
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;