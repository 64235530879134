.news-updates-container {
	margin: 12px;
	background-color: @header-profile-bg;

	@media only screen and (max-width: 576px) {
		margin: 12px auto;
		width: 100vw;
	}

	.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
		transform: rotate(0.5turn);
	}

	.news-container {
		display: flex;

		>div:first-child {
			flex: 3;
			max-width: 75%;
		}

		.ant-divider-vertical {
			height: auto;
		}

		.carousell-container {
			width: auto;
		}

		.carousell-image {
			height: 310px;
			width: 100%;
			object-fit: cover;
		}

		@media only screen and (max-width: 1280px) {
			.carousell-image {
				height: 250px;
			}
		}

		@media only screen and (max-width: 768px) {
			flex-direction: column;

			.carousell-image {
				height: 20vh;
			}

			>div:first-child {
				max-width: calc(100vw - 32px - 24px - 80px);
			}

			>div:last-child {
				margin-top: 8px;
			}
		}

		@media only screen and (max-width: 576px) {
			flex-direction: column;

			>div:first-child {
				max-width: calc(100vw - 32px);
			}

			.carousell-container {
				width: calc(100vw - 32px);
			}

			.carousell-image {
				height: 20vh;
			}
		}

		.divCenter {
			margin: auto;
		}
	}
}

.topnews-container {
	display: flex;
	overflow: auto;
	margin: 4px 8px 8px 8px;
	cursor: pointer;

	img {
		width: 100px;
		object-fit: cover;
	}

	.topnews-details {
		padding: 0 8px 8px 8px;
		display: flex;
		max-width: 250px;
		flex-direction: column;

		.news-title {
			font-weight: bold;
		}

		.grayed-text {
			font-size: 12px;
			font-weight: 400;
			color: #bfbfbf;
		}
	}

	@media only screen and (max-width: 1280px) {
		margin: 4px 8px 4px 0;

		img {
			width: 80px;
		}

		.topnews-details {
			padding: 0 8px 4px 8px;

			>div {
				margin-bottom: 2px;
			}
		}

	}

	@media only screen and (max-width: 768px) {
		margin: 4px 8px 8px 0;

		img {
			width: 80px;
			height: 60px;
		}

		.topnews-details {
			padding: 0 8px 8px 8px;

			>div {
				margin-bottom: 1em;
			}
		}

	}
}

@media only screen and (max-width: 1200px) {
	.topnews-container {
		.topnews-details {
			max-width: 600px;
		}
	}
}
@primary-color: #dcb374;@primary-1: #dcb374;@link-color: #dcb374;@select-item-selected-color: #f3e3b3;@button-text: #fff;@border-color: #000000;@grey-color: #8C8C8C;@gold: #ffd680;@table-header-bg: #B49360;@table-header-color: #ffffff;@table-header-cell-split-color: rgba(255, 255, 255, 0.07);@background-color: #333333;@box-shadow-color: #1a1a1a;@auth-text-color: #333333;@header-profile-bg: #000000;@side-nav-text-color: #fff;@side-nav-icon-color: #f3e3b3;@side-nav-customer-service-bg: #f3e3b3;@side-nav-customer-service-text: #000000;@profile-details-money-font: #002777;@page-container-bg: #000000;@white-bg: #fff;@advisor-avatar-bg: #FEE884;@withdraw-disclaimer-bg: #f3e3b3;@withdraw-disclaimer-border-color: #dcb374;@disabled-buttons-color: #8c8c8c;@card-warm-color: #fffbf1;@table-header-lv-2-bg: #8D734D;@table-header-lv-3-bg: #68563A;